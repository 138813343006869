<template>
	<div class="variant-color-container">
		<BaseInputImage
			data-test-id="previewImage"
			:thumbnail="showPreviewImage"
			title="Add Image"
			class="variant-color-image"
			@onClick="handleClickPreviewImage"
			@onDelete="handleDeletePreviewImage"
		/>
		<div class="color-container">
			<div data-test-id="colorName" class="color-title">
				{{ name }}
			</div>
			<div class="color-detail">
				<div
					class="color-rectangle"
				>
					<div v-if="colorHex" class="w-100 h-100" :style="`background-color: ${colorHex};`">
					</div>
				</div>
				<div data-test-id="colorCode" class="color-code">
					{{ colorHex }}
				</div>
			</div>
		</div>

		<!-- Modal section -->
		<ModalMediaLibrary
			ref="media-library"
			type="single"
			@onSubmit="handleSubmitMedia"
		/>
	</div>
</template>

<script>
import ModalMediaLibrary from '@/components/ModalMediaLibrary.vue';

import { getThumbnailFromMediaObject } from '../assets/js/helpers';

export default {
	name: 'ProductEditVariantColor',

	components: {
		ModalMediaLibrary,
	},

	props: {
		name: {
			type: String,
			default: '',
		},
		previewImage: {
			type: Object,
			default: null,
		},
		colorHex: {
			type: String,
			default: '',
		},
	},

	data() {
		return {
			selectImage: this.previewImage,
		};
	},

	computed: {
		showPreviewImage() {
			return getThumbnailFromMediaObject(this.selectImage);
		},
	},

	methods: {
		handleClickPreviewImage() {
			this.$refs['media-library'].open();
		},
		handleSubmitMedia(file = []) {
			const image = {
				...file[0],
				urls: file[0].imageUrls,
			};
			this.selectImage = image;
			this.$emit('onInputImage', image);
		},
		handleDeletePreviewImage() {
			this.selectImage = null;
			this.$emit('onInputImage', null);
		},
	},
};
</script>

<style lang="scss" scoped>
.variant-color {

	// .variant-color-container
	&-container {
		display: flex;
		background-color: $color-white;
		border-radius: 8px;
	}

	// .variant-color-image
	&-image {
		width: 56px;
		height: 56px;
		margin: 12px 16px;

		&::v-deep {
			.image-link,
			.image-preview,
			.image-placeholder {
				width: 56px;
				height: 56px;
			}

			.image-placeholder {
				font-size: 10px;
			}
		}
	}
}

.color-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 12px 0;

	> .color-title {
		@include typo-body-2;

		line-height: 1;
	}

	> .color-detail {
		display: flex;
		align-items: center;
		gap: 8px;

		> .color-code {
			@include typo-helper-text;

			color: $color-black-90;
		}
	}
}

.color-rectangle {
	width: 40px;
	height: 24px;
	padding: 4px;
	border: 1px solid $color-gray-300;
}
</style>
