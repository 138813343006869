<template>
	<div>
		<BaseLoading
			v-if="edit.isLoading"
			is-full-page
		/>

		<div v-else-if="variantGroups.length" class="mb-5">
			<div v-for="(variant, index) in variantGroups" :key="index" class="variant-container">
				<h4>{{ variant.name }}</h4>
				<div
					class="variant-list"
				>
					<draggable
						:key="variant.id"
						:value="variant.options"
						tag="ul"
						ghost-class="is-placeholder-item"
						class="label-list-dragable"
						@input="(sortList) => handleLabelOrder(sortList, index)"
					>
						<template v-if="variant.type === PRODUCT_GROUPS_TYPE.COLOR">
							<ProductEditVariantColor
								v-for="option in variant.options"
								:key="option.id"
								class="margin-item"
								:preview-image="option.image"
								:name="option.text"
								:color-hex="option.value"
								@onInputImage="(image) => handleInputImage(image, variant.id, option.id)"
							/>
						</template>
						<template v-else>
							<ProductEditVariantText
								v-for="option in variant.options"
								:key="option.id"
								class="margin-item"
								:name="option.value"
							/>
						</template>
					</draggable>
				</div>
			</div>
		</div>

		<span v-else class="subtitle color-black-45 text-center">No Variant in the product </span>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isUpdating"
			content-class="main-wrapper-large"
			is-edit
			data-test-id="save-change-product"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: ROUTE_NAME.PRODUCT_EDIT })"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import draggable from 'vuedraggable';

import ProductEditVariantText from '@/components/ProductEditVariantText.vue';
import ProductEditVariantColor from '@/components/ProductEditVariantColor.vue';

import { ROUTE_NAME } from '../enums/route';
import { PRODUCT_GROUPS_TYPE } from '../enums/productGroups';

export default {
	name: 'ProductEditVariantGroup',

	components: {
		draggable,
		ProductEditVariantText,
		ProductEditVariantColor,
	},

	data() {
		return {
			PRODUCT_GROUPS_TYPE,
			ROUTE_NAME,
			isUpdating: false,
			groupSlug: this.$route.params.slug,
			variantGroups: [],
		};
	},

	computed: {
		...mapState('productGroups', {
			edit: 'edit',
		}),
	},

	async created() {
		await this.getProductGroups(this.groupSlug);
		this.variantGroups = this.edit.data;
	},

	methods: {
		...mapActions({
			getProductGroups: 'productGroups/getProductGroups',
			updateProductGroups: 'productGroups/updateProductGroups',
		}),

		handleLabelOrder(sortedList = [], variantIndex) {
			this.variantGroups[variantIndex].options = sortedList;
		},
		handleInputImage(image, variantId, optionId) {
			const variantIndex = this.variantGroups.findIndex((v) => v.id === variantId);
			if (variantIndex < 0) {
				return;
			}

			const optionIndex = this.variantGroups[variantIndex].options.findIndex((o) => o.id === optionId);
			if (optionIndex < 0) {
				return;
			}

			this.variantGroups[variantIndex].options[optionIndex] = {
				...this.variantGroups[variantIndex].options[optionIndex],
				image,
			};
		},
		handleSubmit() {
			const body = this.variantGroups.map((variant) => {
				return {
					id: variant.id,
					type: variant.type,
					options: variant.options.map((option) => {
						return {
							id: option.id,
							file_id: option.image?.id ?? null,
						};
					}),
				};
			});

			this.updateProductGroups({
				slug: this.groupSlug,
				params: {
					variants: body,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.variant-container {
	max-width: 432px;
	margin: auto;

	> .variant-list {
		background-color: $color-gray-smoke;
		border-radius: 8px;
		padding: 4px;

		> .label-list-dragable {
			margin: 0;
			padding: 0;
		}
	}

	&:not(:first-child) {
		margin-top: 32px;
	}
}

.margin-item:not(:first-child) {
	margin-top: 4px;
}
</style>
