<template>
	<div data-test-id="textName" class="variant-text-container">
		{{ name }}
	</div>
</template>

<script>
export default {
	name: 'ProductEditVariantText',

	props: {
		name: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
	.variant-text-container {
		background-color: $color-white;
		padding: 9px 16px;
		border-radius: 8px;
	}
</style>
